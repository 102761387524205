import { OperatorSetBuilder } from "../../../Components/CommonUtility/ServerSide/FilterComponents/OperatorsBuilder";


interface FilterProps {
  options: any[];
  field: any;
  label?: string;
  updateFilterModel: (field:string,operator:string,value:any) => any;
  filterModel: any;
}

export const GenericStringFilter = ({ options, label }: FilterProps) => {
  const operatorBuilder = new OperatorSetBuilder();
  operatorBuilder.addAutocompleteSingleValue("equals", "equals", options, label?label:"");
  operatorBuilder.addBasicOperator("contains", "contains");
  operatorBuilder.addBasicOperator("does not contain","notContains")
  operatorBuilder.addBasicOperator("starts with", "startsWith");
  operatorBuilder.addBasicOperator("ends with", "endsWith");
  operatorBuilder.addNoValueFilter("is empty", "isEmpty");
  operatorBuilder.addNoValueFilter("is not empty", "isNotEmpty");
  operatorBuilder.addAutocompleteMultipleValue("is any of", "isAnyOf", options, label?label:"");
  return operatorBuilder.build();
}
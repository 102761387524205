import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@material-ui/core";
import { AutocompleteHelper } from "../../../Autocomplete/ObjectExtractor";
import { matchSorter } from "match-sorter";
import "./Filter.css"; 
import { GridFilterItem } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-pro";

interface FilterAutocompleteProps {
  item: GridFilterItem,
  options: any[];
  isMultiple?: boolean;
  labelPath?: string;
}

const FilterAutocomplete = ({item, options = [], isMultiple, labelPath}: FilterAutocompleteProps) => {
  const gridApi = useGridApiContext();

  // Ensure options is always an array
  const safeOptions = Array.isArray(options) ? options : [];

  // Ensure initial value is properly formatted and exists in options
  const [value, setValue] = useState(() => {
    if (isMultiple) {
      // For multiple selection, ALWAYS return an array
      if (!item.value) return [];
      const initialValue = Array.isArray(item.value) ? item.value : [item.value];
      return initialValue.filter(val => 
        safeOptions.some(opt => 
          labelPath ? 
            AutocompleteHelper.getLabelViaInfer(opt, labelPath) === val : 
            opt === val
        )
      );
    } else {
      // For single selection
      if (!item.value) return null;
      const exists = safeOptions.some(opt => 
        labelPath ? 
          AutocompleteHelper.getLabelViaInfer(opt, labelPath) === item.value : 
          opt === item.value
      );
      return exists ? item.value : null;
    }
  });

  // Ensure value is always in correct format based on isMultiple
  const safeValue = isMultiple ? (Array.isArray(value) ? value : value ? [value] : []) : value;

  const filterData = (options: any[], valueToFilterOn: any) => {
    if (!valueToFilterOn) return options.slice(0, 15);
    if (labelPath) {
      return matchSorter(options, valueToFilterOn, { keys: [labelPath] }).slice(0, 15);
    }
    return matchSorter(options, valueToFilterOn).slice(0, 15);
  };

  const handleFilterOptions = (options: any[], params: any) => {
    const { inputValue } = params;
    return filterData(options, inputValue);
  };

  return (
    <Autocomplete
      id={item.field + "-" + item.operator + "- Autocomplete"}
      options={safeOptions}
      value={safeValue}
      multiple={isMultiple}
      onChange={(event, newValue) => {
        // Ensure newValue is always in correct format
        const safeNewValue = isMultiple ? (newValue || []) : newValue;
        setValue(safeNewValue);
        
        if (labelPath) {
          const extractedVal = isMultiple 
            ? safeNewValue.map((val: any) => AutocompleteHelper.getLabelViaInfer(val, labelPath))
            : AutocompleteHelper.getLabelViaInfer(safeNewValue, labelPath);
          gridApi.current.upsertFilterItem({...item, value: extractedVal});
        } else {
          gridApi.current.upsertFilterItem({...item, value: safeNewValue});
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id={item.field + "-" + item.operator + "- AutocompleteTextField"}
          variant="standard"
          margin="dense"
          className="filter-autocomplete"
          autoFocus
        />
      )}
      filterOptions={handleFilterOptions}
      getOptionLabel={(option) => {
        if (!option) return '';
        if (typeof option === 'string') return option;
        return labelPath ? AutocompleteHelper.getLabelViaInfer(option, labelPath) : option.toString();
      }}
    />
  );
};

export default FilterAutocomplete;
